@import url(https://fonts.googleapis.com/css?family=Share+Tech+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#nav-bar {
    margin-left: 40%;
    padding:2%;
}
#left-panel {
    left:0;
    top: 0;
    bottom: 0;
    float:left;
    width:40%;
    height:100%;
    position: fixed;
    background-image: url(/static/media/blog.5c106c7f.jpeg);
    background-position: center;
    background-size:cover;
    padding-top:2%;
}

.right-panel {
    float: right;
    margin-right:10%;
    width:40%;
    overflow: hidden;
}

#title-card {
    width:50%;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    float:right;
    background-color:rgba(255, 255, 255, 0.5);
    padding:2%;
}

#title-text-left-panel {
    text-align: center;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.5em;
}

#top-picture-left {
    width:40vw;
    height:50vh;
    -webkit-transform:rotate(90deg);
    transform: rotate(90deg);
}

.title-about {
    text-align: center;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.3em;
    background-color:#FFFF00;
}

#programming-title {
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.3em;
    background-color:#FFFF00;
    text-align: center;
}

.work-title {
    font-weight: bold;
}

#kw-image {
    width:40vw;
    margin-bottom:2%;
}

#work-1 {
    margin-bottom:30%;
}

#work-2 {
    margin-bottom:30%;
}

#work-3 {
    margin-bottom:30%;
}

#enigma-image {
    width:40vw;
}

#piano-image {
    height: 50vh;
}

#book-image {
    width: 25vw;
}
#contact-title-text {
    text-align: center;
    font-family: 'Share Tech Mono', monospace;
    font-size: 1.3em;
    background-color:#FFFF00;
}
